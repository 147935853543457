<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <h3 class="card-title">Großhändlerbestellung</h3>
                <div class="card-tools">
                    <div class="row align-items-center">
                                <div class="col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  

                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadOrders" :value="search" @input="$store.commit('wholesaler_orders/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadOrders">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>
            
                                            <!-- <li class="nav-item d-none d-md-block">
                                                <router-link class="btn btn-primary btn-xs" :to="{name: 'wholesaler-orders.create'}" v-if="$auth.check('supplier.orders.create')"><i class="fas fa-fw fa-truck"></i> Neue Lieferantenbestellung</router-link>
                                            </li> -->
                                            
                                        </ul>
                    
                                    </nav>
                                </div>
                            </div>
                    
                    
                    
                    
                </div>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <th><input type="checkbox" v-model="selectAll" /></th>
                        <th>Firma</th>
                        <th>Großhändler</th>
                        <th>
                            <a href="#" @click.prevent="changeSort('ordernumber')">Bestellnummer</a>
                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th>Datum</th>
                        <th>Status</th>
                        <th>Aktion</th>
                    </thead>

                    <tbody>
                        <tr v-for="(order) in orders.data" :key="order.id">
                            <td><input type="checkbox" :value="order.id" v-model="selectedOrders"/></td>
                            <td>{{ order.company.name }}</td>
                            <td>{{ order.wholesaler.name }} </td>
                            <td>{{ order.ordernumber }}</td>
                            <td>{{ order.created_at | formatDate}}</td>
                            <td><span class="badge" :class="setStatusBadge(order.status.description)">{{ order.status.description }}</span></td>
                            
                            <td>
                                <button type="button" class="btn btn-primary btn-xs mr-1" @click="downloadPicklist(order)"><i class="fas fa-fw fa-download"></i></button>
                                <router-link v-b-popover.hover.top="'bearbeiten'" class="mr-1 btn btn-warning btn-xs" :to="{name: 'wholesaler-orders.details', params: {id: order.id}}" v-if="order.status.name == 'open'"><i class="fas fa-fw fa-edit"></i></router-link>
                            </td>
                        </tr>
                    </tbody>

                </table>
                </div>
            </div>
            <div class="card-footer">
                <template v-if="orders.data.length > 0">
                    <div class="row align-items-center">
                        <div class="col-md-9">
                            <pagination class="float-left" :data="orders" @pagination-change-page="loadOrders" :limit="3"></pagination>
                        </div>
                        <div class="col-md-2">
                            <span class="float-right">Anzeige Eintrag {{ orders.meta.from }} - {{ orders.meta.to }} von {{ orders.meta.total }}</span>
                        </div>
                        <div class="col-md-1">
                            <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                <option value="25">25 Artikel</option>
                                <option value="50">50 Artikel</option>
                                <option value="75">75 Artikel</option>
                                <option value="100">100 Artikel</option>
                                <option value="125">125 Artikel</option>
                                <option value="150">150 Artikel</option>
                            </select>
                        </div>
                    </div>                        
                </template>
            </div>
        </div>
    </b-container>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'wholesaler-orders.index',
    title: 'Lieferantenbestellungen',

    data() {
        return {
            'orders': {
                data: [],
            },
            selectedOrders: [],

        }
    },

    computed:{
        selectAll: {
            get: function () {
                return this.orders.data ? this.selectedOrders.length == this.orders.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.orders.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedOrders = selected;
            }
        },
        ...mapState('wholesaler_orders', ['params', 'search', 'page'])
    },

    watch: {
        params: {
            handler () {
                this.loadOrders();
            },
            deep: true
        },
    },

    methods: {
        resetSearch(){
            this.$store.commit('wholesaler_orders/changeSearch', '');
            this.loadOrders();
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        setStatusBadge(state){

            if(state == 'Offen')
            {
                return 'badge-danger'
            }

            if(state == 'Gesendet')
            {
                return 'badge-success'
            }

            return 'badge-primary';
        },

        downloadPicklist(order)
        {
            this.axios
                .get("/wholesaler-orders/" + order.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response, order)
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        downloadFile(response, order){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = 'Bestellschein-' + order.ordernumber + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },


        loadOrders(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/wholesaler-orders",{
                    params:{
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.orders = response.data;
                    this.$store.commit('wholesaler_orders/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadOrders();
    }
}
</script>

<style>

</style>